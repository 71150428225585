.ant-menu-submenu-title,
.ant-menu-item.ant-menu-item-only-child:not(:last-child) {
  padding: 0 !important;
  margin: 0 0 10px 0 !important;
}

.ant-menu-item.ant-menu-item-only-child:last-child {
  padding: 0 !important;
  margin: 0 !important;
}

.ant-menu-submenu-arrow,
.ant-dropdown-menu-submenu-arrow {
  display: none;
}

.ant-dropdown-menu-item-selected {
  background-color: unset;
}

.ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border: none;
}
