/* @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Amiri&family=Raleway:wght@200&display=swap");

body {
  font-family: "Raleway", "Helvetica Neue", sans-serif;
  background-color: #fafafa;
  font-size: 16px;
}

a {
  color: inherit;
}

.title {
  font-family: "Amiri", serif;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 100vh;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
